import React from 'react';
import loadable from '@loadable/component';

const PageLink = loadable(() => import('./pageLink'));
const AnchorScrollLink = loadable(() => import('./anchorScrollLink'));
const PageAnchorLink = loadable(() => import('./pageAnchorLink'));
const ExternalLink = loadable(() => import('./externalLink'));
const MailToLink = loadable(() => import('./mailToLink'));

const SwitchLink = ({ _type, ...rest }) => {
  switch (_type) {
    case 'pageLink':
      return <PageLink {...rest} />;
    case 'pageAnchorLink':
      return <PageAnchorLink {...rest} />;
    case 'anchorLink':
      return <AnchorScrollLink {...rest} />;
    case 'externalLink':
      return <ExternalLink {...rest} />;
    case 'mailtoLink':
      return <MailToLink {...rest} />;
    default:
      return null;
  }
};

export default SwitchLink;
