import React from 'react';
import cx from 'classnames';

import CircleBorder from '@svg/circle-border.svg';
import CircleBorderM from '@svg/circle-b-mob.svg';
import ArrowD from '@svg/arrow-d.svg';
import Angles from '@svg/angles.svg';

import PortableTextBlock from '@components/sanity/portableTextBlock';
import ModuleWrapper from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import { animateScroll as scroll } from 'react-scroll';

const handleScrollClick = (distance) => {
  scroll.scrollTo(distance);
};

const ImageBanner = ({ config, bgImage, headline, layout, border, pageBg }) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <ModuleWrapper
      {...config}
      className="text-solar-light w-full h-screen relative flex items-center justify-center px-3 lg:px-gutter bg-black"
      ref={ref}
    >
      {/* Bg */}
      <SanityImage
        image={bgImage}
        className="w-full h-full absolute top-0 left-0 object-cover object-bottom z-0 opacity-80"
      />
      {/* Text */}
      <div
        className={cx('relative z-50 w-full', {
          'text-center max-w-5xl mx-auto': layout === 'center',
          'text-left max-w-6xl mr-auto': layout === 'left',
          'text-right': layout === 'right',
        })}
      >
        <PortableTextBlock text={headline} />
        <button className="" onClick={() => handleScrollClick(height)}>
          <ArrowD className="w-8 fill-solar-light mt-10 md:mt-16 lg:mt-24r " />
        </button>
      </div>
      {/* Anchor Link */}

      {border === 'circle' && (
        <React.Fragment>
          <CircleBorderM className="absolute bottom-0 transform translate-y-1 w-full fill-solar lg:hidden" />
          <CircleBorder className="absolute bottom-0 w-full fill-solar hidden lg:flex" />
        </React.Fragment>
      )}
      {border === 'angles' && (
        <Angles
          className={cx(
            'absolute bottom-0 right-0 w-[300px] lg:w-[700px] transform translate-y-1',
            {
              'fill-solar': pageBg === 1,
              'fill-solar': pageBg === 2,
              'fill-[#FFFEEB]': pageBg === 3,
              'fill-lunar': pageBg === 4,
              'fill-solar-light': !pageBg,
            }
          )}
        />
      )}
    </ModuleWrapper>
  );
};

export default ImageBanner;
