import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

const useNavQuery = () => {
  const { sanitySettingsNav } = useStaticQuery(graphql`
    query {
      sanitySettingsNav {
        mainMenu {
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
        }
      }
    }
  `);
  console.log(sanitySettingsNav);
  return sanitySettingsNav || {};
};

export default useNavQuery;
