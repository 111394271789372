import React from 'react';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';
import cx from 'classnames';

import ArrowR from '@svg/arrow-r.svg';

import SwitchLink from '@components/links/switchLink';
import DataTable from '@components/portableTextItems/dataTable';

import { SanityImage } from '@components/sanity/sanityImage';
const IconSet = loadable(() => import('@components/portableTextItems/iconSet'));
const VideoEmbed = loadable(() => import('@components/media/videoEmbed'));

const PortableTextBlock = ({ text, ...rest }) => {
  return (
    text && (
      <PortableText
        content={text}
        serializers={{
          h0: (props) => <h1 {...props} className="isH0 mb-one" />,
          h1: (props) => <h1 {...props} className="isH1 mb-one" />,
          h2: (props) => <h2 {...props} className="isH2 mb-one" />,
          h3: (props) => <h3 {...props} className="isH3 mb-one" />,
          h4: (props) => <h4 {...props} className="isH4 mb-one" />,
          pageLink: (props) => <SwitchLink {...props} className="btn inline-flex" />,
          pageAnchorLink: (props) => <SwitchLink {...props} className="btn inline-flex" />,
          anchorLink: (props) => <SwitchLink {...props} className="btn" />,
          em: (props) => <span {...props} className="font-serif font-normal text-[105%]" />,
          iconSet: (props) => <IconSet {...props} />,
          normal: (props) => <p {...props} className="mb-one font-h-medium" />,
          figure: (props) => <SanityImage image={props} className="mb-one w-full" />,
          videoEmbed: (props) => <VideoEmbed {...props} className="mb-one" />,
          table: (props) => <DataTable {...props} className="mb-one" />,
          externalLink: ({ buttonDisplay, ...props }) => (
            <SwitchLink
              {...props}
              className={cx('', {
                'font-h-bold flex space-x-2 items-center': !buttonDisplay,
                btn: buttonDisplay,
              })}
            >
              {!buttonDisplay && <ArrowR className="w-3 fill-current" />}
            </SwitchLink>
          ),
          mailtoLink: (props) => <SwitchLink {...props} className="btn" />,
          ul: (props) => (
            <ul className="list-disc pl-[20px]">
              {props.children.map((child) => (
                <li className="mb-half">{child}</li>
              ))}
            </ul>
          ),
          ol: (props) => (
            <ol {...props} className="list-decimal pl-[20px]">
              {props.children.map((child) => (
                <li className="mb-half">{child}</li>
              ))}
            </ol>
          ),
          ctas: (props) => (
            <div className="flex flex-col md:flex-row md:gap-6">
              {props.cta?.map((item) => (
                <SwitchLink className="btn flex justify-center" {...item} />
              ))}
            </div>
          ),
        }}
        {...rest}
      />
    )
  );
};

export default PortableTextBlock;
