import React from 'react';

import useFooterQuery from '@querys/useFooterQuery';
import useSocialQuery from '@querys/useSocialQuery';
import SwitchLink from '@components/links/switchLink';

const StandardFooter = () => {
  const { mainLinks, minorLinks, contactEmail } = useFooterQuery();
  const { socialHandles } = useSocialQuery();
  return (
    <div className="hidden relative z-10 lg:grid lg:grid-cols-12 lg:px-gutter lg:py-16">
      {/* Main Area */}
      <div className="col-start-1 col-end-5">
        <h4 className="font-h-medium">General Enquiries</h4>
        <a href={`mailto:${contactEmail}`} className="font-serif text-2xl">
          {contactEmail}
        </a>
        <div className="flex gap-x-2 text-sm opacity-80 mt-6 font-h-medium">
          <p>Helios &copy; 2022</p>
          <nav className="flex gap-x-2">
            {minorLinks.map((item) => (
              <SwitchLink {...item} />
            ))}
          </nav>
        </div>
      </div>
      {/* Social Area */}
      <div className="col-start-7 col-end-13 grid grid-cols-2">
        <div>
          {socialHandles.length > 0 && (
            <React.Fragment>
              <h4 className="mb-4 font-h-medium">Follow Us</h4>
              <nav className="flex gap-x-4">
                {socialHandles.map((item) => (
                  <a href={item.link} key={item.key}>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.svg }}
                      className="fill-current w-6"
                    />
                  </a>
                ))}
              </nav>
            </React.Fragment>
          )}
        </div>
        <nav className="flex gap-x-4">
          {mainLinks.map((item) => (
            <SwitchLink {...item} className="font-h-medium" />
          ))}
        </nav>
      </div>
    </div>
  );
};

export default StandardFooter;
