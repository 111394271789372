import React from 'react';
import { motion } from 'framer-motion';
const DataRow = ({ cells }) => {
  return (
    <tr>
      {cells.map((item) => (
        <td className="min-w-[240px] py-4">{item}</td>
      ))}
    </tr>
  );
};

const DataTable = ({ rows }) => {
  const headerRows = rows[0];
  const bodyRows = rows.slice(1);
  const ref = React.useRef(null);

  return (
    <div ref={ref} className="w-full overflow-x-scroll">
      <motion.table cellPadding={20} drag="x" dragConstraints={ref}>
        <thead>
          <tr className="border-b border-current">
            {headerRows.cells.map((item) => (
              <th className="min-w-[240px] py-4 text-left font-h-medium font-normal">{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bodyRows.map((item) => (
            <DataRow {...item} />
          ))}
        </tbody>
      </motion.table>
    </div>
  );
};

export default DataTable;
