import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

const useFooterQuery = () => {
  const { sanitySettingsFooter } = useStaticQuery(graphql`
    query {
      sanitySettingsFooter {
        mainLinks {
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
        }
        minorLinks {
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
        }
        contactEmail
      }
    }
  `);
  return sanitySettingsFooter || {};
};

export default useFooterQuery;
