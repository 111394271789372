import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const MenuToggle = ({ isOpen, reverseHeader, width = 20, height = 15, ...props }) => {
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;
  const topLine = useRef(null);
  const bottomLine = useRef(null);
  const middleLine = useRef(null);

  useEffect(() => {
    gsap.to(topLine.current, {
      rotate: () => (isOpen ? 45 : 0),
      translateY: () => (isOpen ? 2 : 0),
      transformOrigin: 'center center',
      ease: 'power2.out',
    });
    gsap.to(middleLine.current, {
      opacity: () => (isOpen ? 0 : 100),
      scale: () => (isOpen ? 0 : 1),
      transformOrigin: 'center center',
    });
    gsap.to(bottomLine.current, {
      rotate: () => (isOpen ? -45 : 0),
      translateY: () => (isOpen ? -2 : 0),
      transformOrigin: 'center center',
      ease: 'power2.out',
    });
  }, [isOpen]);

  return (
    <button {...props} className="relative overflow-visible py-2">
      <svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        strokeWidth="2.5"
        className="stroke-current"
      >
        <line
          x1="0"
          x2={unitWidth}
          y1="0"
          y2="0"
          initial="closed"
          vectorEffect="non-scaling-stroke"
          ref={topLine}
        />
        <line
          x1="0"
          x2={unitWidth}
          y1="2"
          y2="2"
          initial="closed"
          vectorEffect="non-scaling-stroke"
          ref={middleLine}
        />
        <line
          x1="0"
          x2={unitWidth}
          y1="4"
          y2="4"
          initial="closed"
          vectorEffect="non-scaling-stroke"
          ref={bottomLine}
        />
      </svg>
    </button>
  );
};

export default MenuToggle;
