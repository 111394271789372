import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

const useSocialQuery = () => {
  const { sanitySettingsSocial } = useStaticQuery(graphql`
    query {
      sanitySettingsSocial {
        socialHandles {
          key: _key
          svg
          link
        }
      }
    }
  `);
  return sanitySettingsSocial || {};
};

export default useSocialQuery;
