import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import Headroom from 'react-headroom';

import useNavQuery from '@querys/useNavigation';

import Logo from '@svg/logo.svg';

import PageLink from '@components/links/pageLink';
import MenuToggle from './menuToggle';
import NavDraw from './navDraw';

const Header = ({ reverseHeader }) => {
  // State
  const [navOpen, setNavOpen] = React.useState(false);
  const [headerPinned, setHeaderPinned] = React.useState(false);
  // Functions
  const toggleNav = () => setNavOpen((prev) => !prev);
  // Data
  const { mainMenu } = useNavQuery();

  React.useEffect(() => {
    if (headerPinned) {
      document.documentElement.classList.add('header-pinned');
    } else {
      document.documentElement.classList.remove('header-pinned');
    }
    return () => {
      document.documentElement.classList.remove('header-pinned');
    };
  }, [headerPinned]);

  return (
    <React.Fragment>
      <Headroom
        style={{ zIndex: '3000' }}
        wrapperStyle={{
          position: 'absolute',
          left: '0',
          top: '0',
          width: '100%',
        }}
        onPin={() => setHeaderPinned(true)}
        onUnpin={() => setHeaderPinned(false)}
        onUnfix={() => setHeaderPinned(false)}
      >
        <header
          className={cx(
            'flex px-gutter w-screen justify-center items-center py-4 md:py-6 lg:py-8 lg:justify-between',
            {
              'text-lunar': !reverseHeader && !headerPinned && !navOpen,
              'text-solar-light': reverseHeader || headerPinned || navOpen,
              'bg-lunar': headerPinned || navOpen,
              'lg:py-6': headerPinned,
            }
          )}
        >
          <Link
            to="/"
            className={cx('ml-auto transition-all duration-100 w-[122px] md:w-[150px] lg:ml-0', {
              'md:w-[90px]': headerPinned,
            })}
          >
            <Logo className="fill-current" />
          </Link>
          {/* Menu Toggle */}
          <div className="ml-auto lg:hidden">
            <MenuToggle onClick={toggleNav} isOpen={navOpen} />
          </div>
          {/* Desktop Nav */}
          <nav className="hidden lg:flex lg:gap-x-10 2xl:gap-x-14">
            {mainMenu.map((item) => (
              <PageLink {...item} activeClassName="active" className="nav-link" />
            ))}
          </nav>
        </header>
      </Headroom>
      <NavDraw isOpen={navOpen} items={mainMenu} />
    </React.Fragment>
  );
};

export default Header;
