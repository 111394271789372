import React from 'react';
import cx from 'classnames';
import gsap from 'gsap';

const ModuleWrapper = React.forwardRef(
  ({ children, className, paddingTop, paddingBottom, slug, fadeReveal = false }, ref) => {
    // Padding Classes
    const paddingClasses = cx(className && className, {
      'pt-space-s': paddingTop === 'small',
      'pt-space-m': paddingTop === 'medium',
      'pt-space-l': paddingTop === 'large',
      'pb-space-s': paddingBottom === 'small',
      'pb-space-m': paddingBottom === 'medium',
      'pb-space-l': paddingBottom === 'large',
    });

    React.useEffect(() => {
      if (fadeReveal && ref.current) {
        gsap.from(ref.current, {
          opacity: 0,
          scrollTrigger: {
            trigger: ref.current,
            start: 'top center',
          },
        });
      }
    }, [fadeReveal]);

    return (
      <section className={`${paddingClasses}`} id={slug && slug.current} ref={ref}>
        {children}
      </section>
    );
  }
);

export default ModuleWrapper;
