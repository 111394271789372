import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import cx from 'classnames';

import '@css/main.css';

import Header from './navigation/header';
import PageFooter from './footers/pageFooter';
import StandardFooter from './footers/standardFooter';
import ImageBanner from '@components/pageModules/modules/imageBanner';
import PageLoader from './pageLoader';

const Layout = ({
  children,
  hasTransparentHeader,
  pageBg,
  reverseText,
  pageFooter,
  pageBanner,
  reverseHeader,
}) => {
  React.useEffect(() => {
    if (reverseText) {
      document.documentElement.classList.add('rev-text');
    }
    return () => {
      document.documentElement.classList.remove('rev-text');
    };
  }, [pageBg, reverseText]);

  React.useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(DrawSVGPlugin);
  }, []);

  return (
    <>
      <Header reverseHeader={reverseHeader} />
      {pageBanner && <ImageBanner {...pageBanner} pageBg={pageBg} />}
      <main
        id="main-content"
        className={cx('min-h-screen w-screen', {
          'bg-one': pageBg === 1,
          'bg-two': pageBg === 2,
          'bg-three': pageBg === 3,
          'bg-four': pageBg === 4,
          'bg-standard': !pageBg,
          'pt-32 lg:pt-64': !hasTransparentHeader,
        })}
      >
        {children}
        {pageFooter ? <PageFooter {...pageFooter} /> : <StandardFooter />}
      </main>
      <PageLoader />
    </>
  );
};

export default Layout;
