export const useSanityPath = (props) => {
  const { _type, slug } = props || {};

  function getPath(_type, slug) {
    switch (_type) {
      case 'simplePage':
        return `/${slug.current}`;
      case 'modularPage':
        return `/${slug.current}`;
      case 'homePage':
        return `/`;
      case 'blogPortal':
        return `/news`;
      case 'blogPost':
        return `/news/${slug.current}`;
      case 'projectPortal':
        return `/projects`;
      case 'projects':
        return `/projects/${slug.current}`;
      default:
        return null;
    }
  }

  const path = getPath(_type, slug);

  return path;
};
