import React from 'react';
import PageLink from '@components/links/pageLink';
import gsap from 'gsap';
import cx from 'classnames';

import { useArrayRef } from '@hooks/animationHooks';

import useFooterQuery from '@querys/useFooterQuery';
import useSocialQuery from '@querys/useSocialQuery';

const NavDraw = ({ items, isOpen }) => {
  // Querys
  const { contactEmail } = useFooterQuery();
  const { socialHandles } = useSocialQuery();
  // Timeline
  const [menuTl] = React.useState(gsap.timeline({ paused: true }));
  // Refs
  const navDrawRef = React.useRef(null);
  const orbRef = React.useRef(null);
  const [links, setLinks] = useArrayRef();
  // Animation
  React.useEffect(() => {
    menuTl
      .fromTo(
        navDrawRef.current,
        {
          clipPath: 'circle(200px at 180% 50%)',
          ease: 'power2.out',
        },
        {
          clipPath: 'circle(200vh at 100% 50%)',
          duration: 1,
        }
      )
      .from(orbRef.current, { xPercent: 50, ease: 'power2.out' }, 0.2)
      .from(links.current, { opacity: 0, xPercent: 50, stagger: 0.07, ease: 'power2.out' }, 0.3)
      .reverse();
  }, []);

  React.useEffect(() => {
    menuTl.reversed(!isOpen);
    if (isOpen) {
      document.documentElement.classList.add('navOpen');
    } else {
      document.documentElement.classList.remove('navOpen');
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      {/* Bg */}
      <div className="w-full h-full bg-lunar fixed top-0 left-0 z-1000" ref={navDrawRef} />
      <div
        className={cx(
          'w-full h-screen fixed top-0 left-0 z-2000 px-gutter overflow-x-hidden text-solar-light overflow-y-auto pt-[100px] pb-32 lg:hidden',
          {
            'pointer-events-none': !isOpen,
            'pointer-events-auto': isOpen,
          }
        )}
      >
        {/* Main Nav */}
        <nav className="grid">
          {items.map((item) => (
            <div ref={setLinks} className="text-xl text-solar-light py-3 font-h-medium">
              <PageLink {...item} />
            </div>
          ))}
        </nav>
        <div className="mt-6 grid gap-y-3" ref={setLinks}>
          <h4 className="font-h-medium">General Enquiries</h4>
          <a href={`mailto:${contactEmail}`} className="font-serif text-[27px]">
            {contactEmail}
          </a>
          <div>
            {socialHandles.length > 0 && (
              <React.Fragment>
                <h4 className="font-h-medium">Follow us</h4>
                <div className="flex gap-x-6 mt-4">
                  {socialHandles.map((handle) => (
                    <a
                      dangerouslySetInnerHTML={{ __html: handle.svg }}
                      href={handle.link}
                      className="svg-parent w-6"
                    />
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {/* Orb */}
        <div
          className="w-[300px] h-[300px] rounded-full absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 bg-two"
          ref={orbRef}
        />
      </div>
    </React.Fragment>
  );
};

export default NavDraw;
