import React from 'react';
import loadable from '@loadable/component';

const ImageFooter = loadable(() => import('./imageFooter'));
const MapFooter = loadable(() => import('./mapFooter'));
const GridFooter = loadable(() => import('./gridFooter'));
const TextFooter = loadable(() => import('./textFooter'));

const PageFooter = ({ _type, ...props }) => {
  switch (_type) {
    case 'imageFooter':
      return <ImageFooter {...props} />;
    case 'mapFooter':
      return <MapFooter {...props} />;
    case 'gridTextFooter':
      return <GridFooter {...props} />;
    case 'textFooter':
      return <TextFooter {...props} />;
    default:
      return null;
  }
};

export default PageFooter;
